import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
    name: "manual",
    components: {},
    props: {},
    data() {
        return {
            bannerContent: '',
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'manuals', title: this.$t('manuals.title'), slug: ''}
            ],
        }
    },
    created() {
        this.getPageContent()
        this.setRequestFlag(true);
    },
    computed: {
        ...mapGetters({
            manualPage: 'manuals/manualPage',
            globalRequestsFlag: 'system/globalRequestsFlag'
        })
    },

    methods: {
        ...mapActions({
            getPage: 'manuals/GET_MANUAL_PAGE',

        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        getPageContent() {
            let slug = this.$route.params.slug

            this.getPage(slug).then(() => {
                if (this.$route.params.slug) {
                    document.title = `${this.manualPage.title} | karbosnab`
                }

                let obj = {}
                obj.bannerImage = this.manualPage.bannerImage
                obj.description = this.manualPage.description
                obj.titleForBanner = this.manualPage.title
                this.bannerContent = obj
            })
        },

    }
}
